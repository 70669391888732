.btn-reglamento {
  color: #fff;
  border: 1px solid #fff;
  padding: 1rem;
  width: auto;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
}

.btn-reglamento:hover {
  /* background-color: #39435a; */
  background-color: #0086e6;
  border: 1px solid #0086e6;
  color: #fff;
}

.texto-reglamento {
  font-family: Pop-Regular;
  font-size: 15px;
  color: #fff;
  line-height: 30px;
}

.text-container {
  position: relative;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 3rem;
}

.fade-container {
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

@media screen and (max-width: 1000px) {
  .buttons-container {
    flex-direction: column;
  }
}
