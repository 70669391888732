.horario-box{
  background-color: var(--blue-merkademia);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 1rem 3rem;
  padding-top: 2rem;
}

.img-horario{
  width:100%;
}

.tabs-horario-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.calendar-row{
  margin-bottom: 2rem;
  width: 100%;
  max-width: 1440px;
}