.footer-container {
  background-color: var(--blue-merkademia);
  color: white;
  display: flex;
  justify-content: center;
  border-top: 1px solid #5b678d;
  padding-top: 1.5rem;
  position: relative;
}

.row-footer {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 60%;
}

.tit-footer {
  font-family: Pop-Bold;
  font-size: 18px;
  color: #fff;
  padding: 0;
  margin-top: 1rem;
}

.item-footer {
  font-family: Pop-Regular;
  font-size: 15px;
  color: #fff;
  list-style: none;
  line-height: 30px;
}

.links-rrss-footer {
  list-style: none;
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.sitio-web {
  color: white;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
}

.sitio-web:hover {
  text-decoration: underline;
  color: white;
}

@media screen and (max-width: 820px) {
  .rrss-container {
    margin-bottom: 1rem;
    padding-bottom: 3rem;
  }

  .row-footer {
    width: 100%;
  }
}
