.login-box {
  background-image: url(../../assets/images/fondo-login.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--light-blue-merkademia);
  min-height: 100vh;
}

.container-login-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
}

.logo-asec {
  max-width: 300px;
}

.container-login-middle {
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.platform-data-container {
  display: flex;
  margin-top: 40px;
  width: 650px;
  align-items: flex-end;
  justify-content: space-around;
}

.num-platform-data {
  color: white;
  font-size: 30px;
  color: #fff;
  text-align: center;
  padding: 0;
  margin: 0;
  font-family: tipo-bold;
}

.text-platform-data {
  font-size: 17px;
  color: #fff;
  text-align: center;
  padding: 0;
  margin: 0;
  font-family: tipo-light;
}

.platform-data {
  width: calc(100% / 3);
}

.right-bar {
  border-right: 1px solid white;
}

.login-container {
  padding: 1rem;
  background-color: var(--light-blue-merkademia);
  /* min-height: 45vh; */
  width: 440px;
  height: 345px;
  border-radius: 10px;
  position: relative;
}

.login-erap {
  padding: 1rem;
  background-color: var(--light-blue-merkademia);
  position: absolute;
  width: 440px;
  height: auto;
  border-radius: 10px;
  top: -70px;
  left: 0;
  color: #fff;
}

.login-erap:hover {
  cursor: pointer;
  background-color: #2f85e6;
}

.tit-login-container {
  font-size: 25px;
  font-weight: bolder;
  color: white;
  font-size: bolder;
  font-family: tipo-light;
  padding: 10px 0 25px 20px;
}

.input-login {
  height: 50px;
  background-color: transparent;
  border-top: 1px solid #253870;
  border-right: 1px solid #253870;
  border-left: 1px solid #253870;
  border-bottom: 1px solid #545a62;
  color: #888f97 !important;
  font-size: 13px;
  font-family: tipo-light;
}

.img-show-hide-password {
  width: auto;
  height: 20px;
  margin: auto;
}

.btn-login {
  font-size: 16px;
  font-family: tipo-bold;
  color: #fff;
  background-color: #f5673d;
  border: 0;
  margin: 35px 0 25px 0;
  border-radius: 30px;
  width: 100%;
}

.btn-login:hover {
  font-size: 16px;
  font-family: tipo-bold;
  color: #fff;
  background-color: #358fca;
  border: 0;
  margin: 35px 0 25px 0;
  border-radius: 30px;
}

.container-login-bottom {
  display: flex;
  min-height: 20vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.link-asec {
  text-decoration: none;
  font-family: tipo-medio;
  font-size: 1.5rem;
}

.link-asec:hover {
  text-decoration: underline;
}

.links-rrss {
  list-style: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  left: 0px;
  top: 35%;
  background-color: white;
  padding: 1rem;
  border-radius: 0 1rem 1rem 0;
}

.login-hand-icon {
  float: left;
}

@media screen and (max-width: 821px) {
  .login-box {
    background-image: url(../../assets/images/fondo-login.png);
    background-size: 100%;
    height: 150px;
  }

  .login-erap {
    position: relative;
    top: 0;
    border: 2px solid #fff;
    width: fit-content;
    margin: 0.5rem auto 1rem;
  }

  .logo-asec {
    width: auto;
    height: 55px;
  }

  .container-login-top {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .platform-data-container {
    display: flex;
    min-height: 10vh;
    align-items: flex-end;
    justify-content: space-around;
    position: relative;
  }

  .num-platform-data {
    color: white;
    font-size: 20px;
    color: #fff;
    text-align: center;
    padding: 0;
    margin: 0;
    font-family: tipo-bold;
  }

  .text-platform-data {
    font-size: 17px;
    color: #fff;
    text-align: center;
    padding: 0;
    margin: 0;
    font-family: tipo-light;
  }

  .platform-data {
    min-height: 15vh;
  }

  .columna-0-padding {
    padding: 0 !important;
  }

  .login-container {
    width: 100%;
  }

  .links-rrss {
    position: relative;
    background-color: var(--light-blue-merkademia);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .link-asec {
    margin-top: 6rem;
    font-size: 1.3rem;
  }
}
