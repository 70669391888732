.intro-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 2rem;
  max-width: 1240px;
}
.intro-text {
  text-align: justify;
}
.intro-atte {
  margin-top: 70px;
}
.intro-dot-icon {
  width: 20px;
}

@media screen and (max-width: 820px) {
  .intro-box {
    padding: 0 1rem;
  }
  .ev-header-ins {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
