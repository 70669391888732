.go-back {
  color: #fff;
  margin-left: 10%;
  padding-top: 2rem;
}

.go-back:hover {
  cursor: pointer;
  text-decoration: underline;
}

.linea-naranja-int {
  height: 6px;
  background-color: #f8aa2c;
  width: 100%;
  margin-bottom: 2rem;
}

.diagnostic-title {
  font-size: 24px;
  color: #fff;
}

.option-container {
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.checkbox-ev {
  accent-color: #f8aa2c;
  width: 20px;
  height: 20px;
}

.option-container input {
  background-color: var(--blue-merkademia);
}

.label-ev {
  color: #fff;
  font-size: 18px;
}

.ev-buttons-container {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.ev-btn-send,
.ev-btn-cancel {
  color: #fff;
  border: 1px solid #fff;
  padding: 0.5rem 1rem;
  width: 90px;
  text-align: center;
  text-decoration: none;
  background-color: var(--blue-merkademia);
}

.ev-btn-send {
  border-radius: 10px;
  background-color: #0086e6;
}

.ev-btn-cancel {
  border-radius: 10px;
  background-color: rgb(193 29 37 / 70%);
  margin-right: 1rem;
}

.ev-btn-cancel:hover {
  background-color: #c11d25;
  border: 1px solid #c11d25;
}

.ev-btn-send:hover {
  background-color: #0086e6;
  border: 1px solid #0086e6;
  color: #fff;
}

@media screen and (max-width: 820px) {
  .go-back {
    margin-left: 1rem;
  }

  .diagnostic-title {
    padding-left: 1rem;
  }
}
