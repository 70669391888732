.result-card {
  background-color: #006b95;
  border-radius: 10px;
  min-width: 500px;
  min-height: 100px;
  margin-bottom: 3rem;
  border: 5px solid #fff;
}

.per {
  border-right: 1px solid white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  margin: 2rem auto;
}

.res-data {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.res-data p span {
  font-weight: bolder;
}
