.perfil-box {
  background-color: var(--blue-merkademia);
  display: flex;
  justify-content: center;
  width: 100%;
}

.perfil-box > * {
  text-align: justify;
}

.perfil-container {
  width: 70%;
  padding: 3rem 0;
}

.tit-perfil {
  color: white;
  padding-bottom: 3rem;
  font-size: 30px;
}

.texto-perfil {
  font-family: Pop-Regular;
  font-size: 15px;
  color: #fff;
  line-height: 30px;
}

.bajada-perfil {
  color: #fff;
  margin-top: 2rem;
  padding: 1rem;
  text-align: left;
}

.buttons-perfil {
  color: #fff;
  border: 1px solid #fff;
  padding: 0.5rem 1rem;
  width: auto;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  margin: 1rem;
}

.buttons-perfil:hover {
  background-color: #0086e6;
  border: 1px solid #0086e6;
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .perfil-container {
    width: 100%;
  }
}
