.contact-box {
  background-color: var(--blue-merkademia);
  display: flex;
  justify-content: flex-start;
  color: white;
  flex-direction: column;
  padding: 1rem 15%;
  min-height: 72vh;
}

.tit-contact {
  color: white;
  padding-bottom: 3rem;
  font-size: 30px;
  padding-top: 3rem;
}

.img-contact {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  transition: 0.3s;
}

.img-contact:hover {
  transform: scale(1.03);
}

.titulo-seccion-canales {
  color: white;
  padding-bottom: 3rem;
  font-size: 20px;
  margin-top: 2rem;
  text-align: center;
}

.mail-link {
  color: white;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

.mail-link:hover {
  color: white;
  text-decoration: underline;
}

@media screen and (max-width: 1200px) {
  .img-contact {
    width: 100%;
    height: auto;
    margin-top: 2rem;
  }

  .titulo-seccion-canales {
    margin-top: 2rem;
    padding-bottom: 0;
  }
}
