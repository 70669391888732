:root{
  --light-blue-merkademia: #253870;
  --blue-merkademia: #212735;
}

body{
  background-color:var(--blue-merkademia)
}

@font-face {
  font-family: tipo-bold;
  src: url(../fonts/tipo-bold.otf);
}

@font-face {
  font-family: tipo-light;
  src: url(../fonts/tipo-light.otf);
}

@font-face {
  font-family: tipo-medio;
  src: url(../fonts/tipo-medio.otf);
}

@font-face {
  font-family: Pop-Bold;
  src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: Pop-Medium;
  src: url(../fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: Pop-Regular;
  src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: Open-Bold;
  src: url(../fonts/OpenSans-Bold.ttf);
}


@font-face {
  font-family: Open-Light;
  src: url(../fonts/OpenSans-Light.ttf);
}

@font-face {
  font-family: Open-Regular;
  src: url(../fonts/OpenSans-Regular.ttf);
}