.profile-box{
  background-color: var(--blue-merkademia);
  display: flex;
  justify-content: center;
  width: 100%;
  min-height:70vh;
}

.profile-container{
  width: 70%;
  padding: 3rem 0;
}

.tit-profile{
  color: white;
  padding-bottom: 3rem;
  font-size: 30px;
}

.top-profile-section{
  border-bottom: 1px solid #4d566a;
  padding-bottom: 1rem;
}

.profile-img{
  width: 160px;
  height: 160px;
  /* border-radius: 10px; */
  border: 2px solid white;
  /* border-radius: 50%; */
  /* border: 6px solid #ff9c00ed; */
}

.btn-update{
  background-color: #ff9c00ed;
  border: 1px solid #ff9c00ed;
  cursor: not-allowed;
  /* color: white; */
}

.btn-update:hover{
  cursor: not-allowed;
}

@media screen and (max-width:1200px){
  .profile-container{
    display:flex;
    flex-direction: column;
  }

  .profile-container .form-label span{
    margin-left:5px;
  }
}