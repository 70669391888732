.diagnostic-box {
  background-color: var(--blue-merkademia);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  color: white;
  padding-top: 3rem;
  gap: 1rem;
}

.mis-d-tit {
  padding-bottom: 5rem;
}

.diagnostic-container {
  width: 80%;
  margin-bottom: 2rem;
}

.linea-naranja {
  height: 6px;
  background-color: #f8aa2c;
  width: 100%;
}

.linea-naranja-2 {
  height: 6px;
  background-color: #f8aa2c;
  max-width: 400px;
  margin: auto;
}

.btn-diagnostic {
  color: #fff;
  border: 1px solid #fff;
  padding: 0.5rem 1rem;
  width: auto;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  width: 200px;
}

.btn-diagnostic:hover {
  /* background-color: #39435a; */
  background-color: #0086e6;
  border: 1px solid #0086e6;
  color: #fff;
  cursor: pointer;
}

.completed {
  background-color: green;
}

.completed:hover {
  background-color: lightgreen;
  border: 1px solid lightgreen;
}

.header-erap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.header-erap-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.left-header-erap {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 1rem;
}

.ev-tit {
  margin-bottom: 0;
}

.btn-ev-progress {
  background-color: rgb(248 170 44 / 70%);
}

.btn-ev-progress:hover {
  background-color: rgb(248 170 44);
  border: 1px solid rgb(248 170 44);
}

@media screen and (max-width: 1200px) {
  .linea-naranja-2 {
    margin: 0;
  }
}
@media screen and (max-width: 820px) {
  .diagnostic-container {
    width: 100%;
    padding: 0.5rem 1rem;
  }
  .header-erap {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
  .mis-d-tit {
    padding-bottom: 2rem;
  }
}
