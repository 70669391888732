.banner-curso {
  width: 100%;
  height: 450px;
}

.detail-course-container {
  /* margin-top: 3rem; */
  background-color: var(--blue-merkademia);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0rem 0%;
  min-height: 72vh;
  max-height: calc(100vh - 82px);
}

.tit-detail-course {
  color: #68b7ca;
  padding-bottom: 3rem;
  font-size: 30px;
}

.btn-evaluacion {
  font-family: Pop-Bold;
  font-size: 16px;
  color: #fff;
  background-color: #006b95;
  border-radius: 25px;
  border: 0;
  padding: 16px 0 16px 0;
  width: 100%;
  margin-top: 10px;
}

.btn-evaluacion:hover {
  background-color: #69b7cb;
}

.tabs-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}
.group-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 1rem 0;
  color: #fff;
  font-size: 20px;
  padding: 1rem 0 0 1rem;
}
.group-icon {
  padding-right: 0.8rem;
}

.btn-tab-course {
  font-family: Pop-Regular;
  font-size: 13px;
  color: #b2b2b2;
  background-color: #15171f;
  border-radius: 25px;
  border: 1px solid #454c63;
  padding: 0.8rem 1rem;
  width: 32%;
}

.btn-tab-course:hover {
  background-color: #69b7cb;
  border: 0;
}

.content-container {
  padding: 0 0 0 0;
  background-color: #15171f;
  margin: 0 0 0 0;
  overflow-y: scroll;
}

@media screen and (max-width: 820px) {
  .banner-curso {
    height: 100px;
  }
}

@media screen and (max-width: 1300px) {
  .btn-tab-course {
    width: 100%;
    margin: 0.5rem 0;
  }
}
