.course-preview-container {
  width: 100%;
  padding: 2rem 1rem 3rem 1rem;
}

.tit-course-preview {
  font-family: Open-Bold;
  font-size: 22px;
  color: #f9f9f9;
  margin: 0;
  padding: 20px 0 5px 0;
  font-weight: 900;
}

.subtit-course-preview {
  font-family: Open-Regular;
  color: #999999;
  margin: 0;
  width: 70%;
}

.img-curso {
  backface-visibility: hidden;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  cursor: pointer;
  display: block;
  position: relative;
  transform: scale(1, 1) translateZ(0px);
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  -webkit-font-smoothing: subpixel-antialiased;
  max-width: 100%;
  height: auto;
}

.img-curso:hover {
  box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
    rgb(0 0 0 / 72%) 0px 30px 22px -10px;
  transform: scale(1.05, 1.05) translateZ(0px) translate3d(0px, 0px, 0px);
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
}

@media screen and (max-width: 1000px) {
  .img-curso {
    margin-bottom: 1rem;
  }
  .tit-course-preview {
    width: 100%;
  }
  .subtit-course-preview {
    width: 100%;
  }
}
