.img-banner {
  max-height: 400px;
  height: auto;
  width: 100%;
}

@media screen and (min-width: 0px) and (max-width: 820px) {
  .img-banner {
    min-height: 200px;
    height: auto;
  }
}
