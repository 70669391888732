.perfil-box {
  background-color: var(--blue-merkademia);
  display: flex;
  justify-content: center;
  width: 100%;
}

.perfil-box > * {
  text-align: justify;
}

.perfil-container {
  width: 70%;
  padding: 3rem 0;
}

.tit-perfil {
  color: white;
  padding-bottom: 3rem;
  font-size: 30px;
}

.texto-perfil {
  font-family: Pop-Regular;
  font-size: 15px;
  color: #fff;
  line-height: 30px;
}

/* .imagen-que-es-merkademia {
  width: 50%;
  max-width: 700px;
  min-width: 600px;
  height: auto;
  margin-bottom: 2rem;
} */

.imagen-que-es-merkademia {
  width: 100%;
  /* max-width: 700px;
  min-width: 600px; */
  height: auto;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1000px) {
  .perfil-container {
    width: 100%;
    margin: 0 1rem;
  }
}
