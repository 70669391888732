.row-container {
  padding: 1rem 1.2rem;
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #454255;
  min-height: 110px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.wrapper-row {
  min-height: 110px !important;
  transition: 0.3s ease;
}
.wrapper-row:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.wrapper-row:hover {
  background-color: #212735;
  border: 1px solid #454255;
}
.title-btn {
  color: #b2b2b2;
  font-size: 18px;
}

.circle-btn {
  border: 1px solid #454255;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-btn p {
  margin: 0;
  color: #454255;
  font-size: 12px;
}

.title-container {
  margin-right: auto;
}
