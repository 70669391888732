.navbar-container {
  background-color: var(--blue-merkademia);
  border-bottom: 1px solid #766453;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  min-height: 89px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.logo-navbar-desktop {
  width: 180px;
  transition: 0.5s;
}

.logo-navbar-desktop:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-links {
  display: flex;
  margin-top: 1rem;
  position: relative;
}

.ul-links {
  list-style: none;
  display: flex;
  color: #999;
  gap: 1.5rem;
  transition: all 0.3s ease-out;
  align-items: center;
}

.navbar-item {
  color: #999 !important;
  transition: 0.3s;
  cursor: pointer;
}

/* .navbar-item:hover{
  cursor: pointer;
  color: white;
  transform: scale(1.1)
} */

.background-light {
  background-color: var(--light-blue-merkademia);
}

.width-100 {
  width: 100%;
}

.blank-space {
  margin-left: auto;
}

.img-perfil-rounded {
  border-radius: 50%;
  max-width: 50px;
}

@media screen and (max-width: 1550px) {
  .navbar-item {
    font-size: 13px;
  }
}

@media screen and (max-width: 1200px) {
  .navbar-left {
    display: unset;
  }

  .navbar-item:hover {
    transform: none;
  }
}

p {
  margin: 0;
}
