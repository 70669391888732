.footer-tipddy {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
}

.text-tipddy {
  font-size: 11px;
  color: #fff;
  font-family: tipo-light;
  text-align: right;
}

@media screen and (max-width: 820px) {
  .text-tipddy {
    margin: 0;
  }

  .footer-tipddy {
    bottom: 0px;
  }
}
