.diagnostic-container2 {
  display: none;
}

.ev-btn-next {
  border-radius: 10px;
  width: auto;
  background-color: var(--blue-merkademia);
}

.ev-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 10% 0 10%;
}

.ev-header-ins {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 10% 0 10%;
}

.ev-buttons-container2 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 3rem;
  padding: 0 10%;
}

.tit-header {
  width: 85%;
}

.question-counter {
  width: 15%;
  text-align: center;
  font-size: 24px;
  padding-bottom: 3rem;
}

.erap-timer {
  color: #fff;
  font-size: 18px;
  font-weight: bolder;
  transition: 0.5s ease-in-out;
}

@media screen and (max-width: 1200px) {
  .header-erap {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: start;
  }
  .question-counter {
    text-align: start;
  }
}

@media screen and (max-width: 820px) {
  .ev-buttons-container2 {
    padding: 0 1rem;
  }
  .ev-header {
    padding-left: 1rem;
  }
}
